import React from "react";
import Layout from "../../components/Layout";

// eslint-disable-next-line
export default () => (
  <Layout>
    <section 
      className="section section--gradient side-fun contact-form"
      style={{
        backgroundImage: `url(/img/header-bg-waves.jpg)`,
        backgroundPosition: `center bottom`,
        backgroundAttachment: `fixed`,
        
      }}
    > 
      <div className="container">
        <div className="content">
          <h1>Thank you!</h1>
          <p className="thanks">We will get back to you soon</p>
        </div>
      </div>
    </section>
  </Layout>
);
